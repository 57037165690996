import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'
import Presentation from '../pagesSections/wl/ProductPresentation/assets/product-presentation.png'
import PresentationBY from '../pagesSections/wl/ProductPresentation/assets/product-presentation-BY.png'
import PresentationKZ from '../pagesSections/wl/ProductPresentation/assets/product-presentation-KZ.png'

import ProductPresentation from '../pagesSections/wl/ProductPresentation'
import Advantages from '../components/_V2/Advantages'
import BusinessTypes from '../components/_V2/BusinessTypes'

import SectionWLDelivery from '../pagesSections/wl/SectionWLDelivery'
import SectionWLMobileFeatures from '../pagesSections/wl/SectionWLMobileFeatures'
import SectionWLAppSiteFeatures from '../pagesSections/wl/SectionWLAppSiteFeatures'
import SectionWLUniqDesign from '../pagesSections/wl/SectionWLUniqDesign'
import SectionWLCRMFeatures from '../pagesSections/wl/SectionWLCRMFeatures'
import SectionWLReportFeatures from '../pagesSections/wl/SectionWLReportFeatures'
import SectionWLTelegramBot from '../pagesSections/wl/SectionWLTelegramBot'
import CtaForm from '../pagesSections/index/CtaForm'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import AdditionalServices from '../pagesSections/index/AdditionalServices'

import { advantagesSlides, getAdvantagesSlides } from '../pages-data/_V2/wl/advantages'
import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { externalLinks } from '../pages-data/_V2/common/links'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/wl.module.scss'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function WlPage() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Мобильное приложение для ресторанов и кафе с системой лояльности | Quick Resto',
    'ru-KZ': 'Мобильное приложение для ресторанов и кафе с системой лояльности | Quick Resto Казахстан',
    'kz-KZ': 'Мобильное приложение для ресторанов и кафе с системой лояльности | Quick Resto Казахстан',
    'ru-BY': 'Мобильное приложение для ресторанов и кафе с системой лояльности | Quick Resto Беларусь',
  }
  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}wl/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}wl/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}wl/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}wl/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Мобильное приложение для ресторанов и кафе от Quick Resto → Программа лояльности гостей → Захватывайте новую аудиторию → Увеличивайте выручку',
    'ru-KZ': 'Мобильное приложение для ресторанов и кафе от Quick Resto → Программа лояльности гостей → Захватывайте новую аудиторию → Увеличивайте выручку. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Мобильное приложение для ресторанов и кафе от Quick Resto → Программа лояльности гостей → Захватывайте новую аудиторию → Увеличивайте выручку. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Мобильное приложение для ресторанов и кафе от Quick Resto → Программа лояльности гостей → Захватывай новую аудиторию → Увеличивай выручку. Автоматизируй бизнес в Беларуси',
  }

  const metaImages = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Presentation}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKZ}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKZ}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresentationBY}`,

  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    // {
    //   name: 'keywords',
    //   content: 'программа, лояльность, гости, мобильное приложение, предзаказ, пуш-уведомление, автоматизация, кафе, ресторан',
    // },
    {
      property: 'og:url',
      content: metaUrl[localizationContext.locale],
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: metaImages[localizationContext.locale],
    },
  ]
  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={pageStyles.pageWrapper}
      linkCanonical={currentCanonicalLink}
      footerClassName={pageStyles.pageSection}
      schemaType="Product"
      schemaName={currentTitle}
      schemaDescription={metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        items={getAdvantagesSlides(localizationContext.locale)}
        hideImages={false}
        className={classNames(styles.wl__advantages, pageStyles.pageSection)}
      />

      <SectionWLDelivery className={pageStyles.pageSection} />

      <SectionWLMobileFeatures className={pageStyles.pageSection} />

      <SectionWLAppSiteFeatures className={pageStyles.pageSection} />

      <SectionWLTelegramBot className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <SectionWLUniqDesign className={pageStyles.pageSection} />

      <SectionWLCRMFeatures className={pageStyles.pageSection} />

      <SectionWLReportFeatures className={pageStyles.pageSection} />

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes
        key={localizationContext.locale}
        data={businessTypes(localizationContext.locale)}
        className={classNames(pageStyles.pageSection, styles.wl__business)}
      />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
